import React from "react";
import { HeaderSection } from ".";
import BannerImge from "../assets/img/Games collage.svg";
import Pickyourteam from "../assets/img/Pick-your-team.svg";
import Cricket from "../assets/img/Cricket.svg";
import Tennis from "../assets/img/Tennis.svg";
import Basketball from "../assets/img/Basketball.svg";
import Football from "../assets/img/Football.svg";
import Badminton from "../assets/img/Badminton.svg";
import Tabletennis from "../assets/img/Table tennis.svg";
import swimming from "../assets/img/swimming.svg";
import { motion } from "framer-motion";
import { Stack } from "@mui/material";

export const Banner = () => {
  return (
    <div className="banner-wrapper">
      <div className="banner-section">
        <motion.div
          className="pick-your-team"
          initial={{ opacity: 1, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <img src={Pickyourteam} alt="image" />
        </motion.div>
        <div className="banner-img-block">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <img src={Tennis} alt="image" />
          </motion.div>
          <motion.div
            className="img-grp"
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <img src={Basketball} alt="image" />
            <img src={Cricket} alt="image" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <img src={Football} alt="image" />
          </motion.div>
          <motion.div
            className="img-grp-2"
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <img src={Badminton} alt="image" />
            <img src={Tabletennis} alt="image" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <img src={swimming} alt="image" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};
