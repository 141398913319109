import { Stack } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AppStore from "../assets/img/App-Store.svg";
import GooglePlay from "../assets/img/Google-Play.svg";
import Logo from "../assets/img/GameTeamLuanch3.svg";
import React from "react";
import { Link } from "react-router-dom";
import { color } from "framer-motion";

export const Footer = () => {
  return (
    <Stack direction={"row"} justifyContent={"center"}>
      <Stack className="footer" justifyContent={"space-between"}>
        <div className="footer-section">
          <div className="footer-block">
            <img className="logo" src={Logo} />
            <span className="game2">GAME</span>
            <span className="team2">TEAM</span>
          </div>
          <div className="footer-block-2">
            <img src={GooglePlay} alt="image" />
            <img src={AppStore} alt="image" />
          </div>
        </div>
        <Stack spacing={3}>
          <Link to="/terms-and-conditions" className="footer-link">
            Terms & Conditions
          </Link>
          <Link to="/privacy-policy" className="footer-link">
            Privacy Policy
          </Link>
          <Link to="/faq" className="footer-link">
            FAQ
          </Link>
        </Stack>
        <Stack alignSelf="center">
          <Link to="https://codinghands.in/" className="footer-link">
            Copyright © 2024 - Coding Hands Infotech LLP
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
