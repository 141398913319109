import React from "react";
import iOSBox from "../assets/img/iOS.svg";
import AndroidOS from "../assets/img/Android OS.svg";
import screenOne from "../assets/img/Screen shot1.svg";
import activity from "../assets/img/find-activity.svg";
import startGroup from "../assets/img/start-group.svg";
import screenTwo from "../assets/img/screen_shot-2.svg";
import DownloadText from "../assets/img/Download Text.svg";
import activityscreen5 from "../assets/img/activity-screen-5.svg";
import createNewActivity from "../assets/img/create-new-activity.svg";
import { Grid, Stack } from "@mui/material";

export const ActivityPage = () => {
  return (
    <>
      <div className="activity-page-one">
        <Grid container spacing={2} className="activity-page-one-grid">
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="left-block">
              <div className="activity-near-you">
                <img src={activity} alt="" className="activity-near-you-img" />
              </div>
              <div className="discription-block">
                <div>
                  Find nearby activities and join them. Remember, you can also
                  tag your friends on the go.
                </div>
                <div>Didn’t find one suitable, create yours within seconds</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="right-block">
              <div className="right-wrapper">
                <img src={screenOne} alt="" className="screen-one" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <div className="activity-page-two">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} className="left-block">
              <div className="img-container">
                <img src={screenTwo} alt="" className="screenTwo" />
              </div>
              <img src={screenTwo} alt="" className="screenTwo-phone-size" />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              justifyContent={"center"}
            >
              <div className="right-block">
                <div>
                  <img src={createNewActivity} alt="" className="" />
                </div>
                <div className="discription-block">
                  <div>
                    Find nearby activities and join them. Remember, you can also
                    tag your friends on the go.
                  </div>
                  <div>
                    Didn’t find one suitable, create yours within seconds
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Stack pt={25} pb={10} width={"100%"} justifyContent={"center"}>
        <div className="activity-page-section">
          <div className="content-wrapper">
            <span className="Privacy-Policy">
              Stay connected with
              <span className="Game-Team"> Game Team.</span> Get instant updates
              on activities around you. Or create a new one.!
            </span>
            <span className="terms-section">
              By installing Game Team, you agree to our Terms & Privacy Policy.
            </span>
          </div>
          <div>
            <div className="content-block-3">
              <div className="content-section">
                <img
                  src={DownloadText}
                  alt="image"
                  className="DownloadText-img"
                />
                <div className="os-img">
                  <img src={AndroidOS} alt="image" />
                  <img src={iOSBox} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Stack>
      <Stack
        width={"100%"}
        justifyContent={"center"}
        className="activity-page-5"
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <div className="img-block">
              <img src={startGroup} alt="" />
              <span>
                Find nearby activities and join them. Remember, you can also tag
                your friends on the go
              </span>
              <span>
                Didn’t find one suitable, create yours within seconds.
              </span>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            className="mob-screen-layout"
          >
            <img src={activityscreen5} alt="" />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
